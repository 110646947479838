import {
  PrimaryCta,
  SecondaryCta,
} from '@rsa-digital/evo-shared-components/components/Cta';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { themeSpecificColors } from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: none;

  ${mediaQuery.tabletLandscape` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 650px;
    position: absolute;
    width: 50%;
    z-index: 1;
  `}

  ${mediaQuery.desktop`
    width: 37%;
  `}
`;

export const CornerImage = styled.div`
  background-size: 1000px;
  display: none;
  height: 650px;
  width: 1000px;
  background-repeat: repeat-x;

  ${mediaQuery.tabletLandscape`
    display: block;
  `}
`;

export const Curve = styled.img`
  display: none;

  ${mediaQuery.tabletLandscape`
    display: block;
    height: 650px;
    position: absolute;
    margin-right: calc(50vw - 350px);
  `}

  ${mediaQuery.desktop`
    margin-right: calc(29vw + 22.5px);
  `}

  @media (min-width: 1600px) {
    margin-right: calc(300px + 12.5vw);
  }

  @media (min-width: 2000px) {
    margin-right: calc(216.67px + 16.67vw);
  }

  @media (min-width: 2600px) {
    margin-right: calc(50vw - 650px);
  }

  @media (min-width: 2700px) {
    margin-right: calc(33.33vw - 200px);
  }

  @media (min-width: 2850px) {
    margin-right: 750px;
  }
`;

export const Heading = styled.h1`
  color: ${colors.neutral08};
  font-size: 3.25rem;
  line-height: 3.75rem;
  margin-top: ${spacing(1.5)};
  margin-bottom: ${spacing(1.5)};

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(1.5)};
    margin-bottom: ${spacing(2)};
    ${fonts.headingXLarge};
    & {
      color: ${colors.neutral08};
      line-height: 80px;
    }
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 calc(50% - 50vw);
`;

export const MobileContainer = styled.div`
  margin-top: -${spacing(45)};
`;

export const MobileContentWrapper = styled.div`
  background: #54187b;
  margin-top: -${spacing(8)};
  padding: ${spacing(3)};
`;

export const MobileCurve = styled.img`
  width: 100%;

  ${mediaQuery.tabletPortrait`
    margin-bottom: -${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: 0;
  `}
`;

export const MobileImage = styled.div`
  background-size: calc(300px + 74vw);
  background-position: right -3vh top -105px;
  height: calc(455px + 18vw);
  width: 100%;
  background-repeat: repeat-x;
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(3)};
  align-items: flex-start;
  flex-wrap: wrap;
  margin: ${spacing(3)} 0;
  & > a {
    margin-left: 0;
  }

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
    gap: ${spacing(4)};  
    align-items: center;
  `}
`;

export const StyledPrimaryCta = styled(PrimaryCta)`
  margin-left: ${spacing(2)};
  padding-left: 24px;
  min-width: 275px;

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(2.25)};
    min-width: auto;
  `}
`;

export const StyledSecondaryCta = styled(SecondaryCta)`
  margin-left: ${spacing(2)};
  padding-left: 24px;
  min-width: 275px;

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(2.25)};
    min-width: auto;
  `}
`;

export const Subhead = styled(RichText)`
  ${fonts.paragraph};
  color: white;
`;

export const Wrapper = styled.div`
  ${mediaQuery.tabletLandscape`
    background: #391959;
    margin: 0 calc(50% - 50vw);
    padding: 0 calc(50vw - 50%);
  `}
`;

export const BottomSpacing = styled.div<{
  spaceHeight: number;
  addBackgroundColour: boolean;
}>`
  height: ${(props) => spacing(props.spaceHeight)};
  background-color: ${(props) =>
    props.addBackgroundColour ? themeSpecificColors.core01_10 : colors.neutral08};
  margin: 0 calc(50% - 50vw);
`;
