import React from 'react';
import HeroCurved from 'components/Hero/HeroCurved';
import { processImageAsset, processOptionalCta } from 'helpers/csTypeProcessors';
import { CsAsset, CsCta } from 'types/contentStack';

export type HeroWithSideImageData = {
  heading: string;
  subheading: string | null;
  image: CsAsset | null;
  primary_cta: [CsCta] | null;
  secondary_cta: [CsCta] | null;
  bottom_space_height: string | null;
  add_background_colour_to_bottom_space: boolean | null;
};

type HeroWithSideImageBlockProps = {
  hero_with_side_image: HeroWithSideImageData;
};

const HeroCurvedBlock: React.FC<HeroWithSideImageBlockProps> = ({
  hero_with_side_image,
}) => {
  // We parse the height as float to allow for smaller spacing steps
  // For instance, spacing(1.5), equivalent to 12px
  const bottomSpaceHeight = parseFloat(hero_with_side_image?.bottom_space_height ?? '0');
  const addBackgroundColourToBottomSpace = !!hero_with_side_image?.add_background_colour_to_bottom_space;

  return (
    <HeroCurved
      image={processImageAsset(hero_with_side_image.image)}
      heading={hero_with_side_image.heading}
      subhead={hero_with_side_image.subheading ?? undefined}
      primaryCta={processOptionalCta(
        hero_with_side_image.primary_cta,
        'CtaBannerPrimaryCta'
      )}
      secondaryCta={processOptionalCta(
        hero_with_side_image.secondary_cta,
        'CtaBannerSecondaryCta'
      )}
      bottomSpaceHeight={bottomSpaceHeight}
      addBackgroundColourToBottomSpace={addBackgroundColourToBottomSpace}
      data-cy="HeroBannerCurved"
    />
  );
};

export default React.memo(HeroCurvedBlock);
